import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../images/home.jpg"
import Web from "../images/web-programming.svg"
import Realizacje from "../images/realizacje.jpg"
import Omnie from "../images/o-mnie.jpg"
import Kontakt from "../images/kontakt.jpg"
import CardDeck from "react-bootstrap/CardDeck"
import Card from "react-bootstrap/Card"
import Envelope from "../images/envelope.svg"
import Mobile from "../images/mobile.svg"
import Ecommerce from "../images/online-shopping.svg"
import Safe from "../images/browser.svg"
import { AnchorLink } from "gatsby-plugin-anchor-links";

const IndexPage = () => (
  <Layout>
    <SEO title="Strony i sklepy internetowe - Web Developer" />

    <div class="container pt-4 pb-5">
      <div class="row align-items-center">
      <div class="col-md-6 col-sm-12 order-lg-2">
          <img src={Home} alt="" />
        </div>
        <div class="col-md-6 col-sm-12 order-lg-1">
            <h1 class="mb-2">Marcin Gabryel</h1>
            <p class="lead mb-4">Web Developer</p>
            <p class="lead text-muted">Zajmuję się tworzeniem nowoczesnych stron i sklepów internetowych. Od ponad 3 lat zawodowo świadczę usługi programistyczne oraz marketingowe.</p>
            <AnchorLink className="btn btn-primary" to="#oferta">Sprawdź ofertę</AnchorLink>
            <AnchorLink className="ml-4" to="#kontakt">Nawiąż kontakt <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-right-short" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
</svg></AnchorLink>
        </div>
      </div>
    </div>

    <div id="oferta" class="container-fluid py-5 bg-light">
      <div class="row py-3 px-2 mx-auto mb-3">
        <div class="col-sm-12 col-md-4 text-center">
          <img src={Web} class="card-svg" />
          <h3>Strony internetowe</h3>
          <p>Zaprojektuję dla Ciebie stronę internetową opartą o ultra szybką technologię ReactJS lub wygodny w użyciu system CMS Wordpress.</p>       
        </div>
        <div class="col-sm-12 col-md-4 text-center">
          <img src={Ecommerce} class="card-svg" />
          <h3>E-commerce</h3>
          <p>Planujesz rozpocząć biznes online lub przenieść obecną działalność do sieci? Wykonam dla Ciebie nowoczesny sklep internetowy na systemie Woocommerce.</p>       
        </div>
        <div class="col-sm-12 col-md-4 text-center">
          <img src={Safe} class="card-svg" />
          <h3>Opieka informatyczna</h3>
          <p>Potrzebujesz wsparcia i opieki związanej z administracją strony/sklepu lub planujesz wprowadzenie większych zmian? Zgłoś się do mnie, a chętnie pomogę.</p>       
        </div>
      </div>
    </div>


    <div class="container py-5">
      <div class="row row-grid align-items-center justify-content-around">
        <div class="col-md-6 col-sm-12 order-lg-1">
          <img src={Realizacje} alt="" />
        </div>
        <div class="col-md-6 col-sm-12 order-lg-2 my-auto">
            <h2 class="mb-2">Moje<span class="red-dot">_</span>realizacje</h2>
            <p class="lead mb-4">#Portfolio</p>
            <p>Przejrzyj moje ostatnie realizacje stron oraz sklepów internetowych. Zobacz z kim miałem okazję współpracować i jak wyglądają moje prace.</p>
            <Link className="btn btn-primary" to="/realizacje">Sprawdź portfolio</Link>
        </div>
      </div>
    </div>

    <hr></hr>

    <div class="container py-5">
      <div class="row row-grid align-items-center justify-content-around">
        <div class="col-md-6 col-sm-12 order-lg-2">
          <img src={Omnie} alt="" />
        </div>
        <div class="col-md-6 col-sm-12 order-lg-1 my-auto">
            <h2 class="mb-2">Poznaj<span class="red-dot">_</span>mnie</h2>
            <p class="lead mb-4">#JakDziałam</p>
            <p>Dowiedź się więcej o moim doświadczeniu i dlaczego warto nawiązać ze mną współpracę.</p>
            <Link className="btn btn-primary" to="/o-mnie">Przeczytaj o mnie</Link>
        </div>
      </div>
    </div>

    <hr></hr>

    <div id="kontakt" class="container py-5">
      <div class="row row-grid align-items-center justify-content-around">
        <div class="col-md-6 col-sm-12 order-lg-1">
          <img src={Kontakt} alt="" />
        </div>
        <div class="col-md-6 col-sm-12 order-lg-2 my-auto">
            <h2 class="mb-2">Nawiąż<span class="red-dot">_</span>kontakt</h2>
            <p class="lead mb-4">#Porozmawiajmy</p>
            <p class="mb-4">Jeżeli masz pytania odnośnie wyceny lub po prostu chciałbyś nawiązać ze mną kontakt - pisz śmiało na <a href="mailto:kontakt@mgabryel.com">kontakt@mgabryel.com</a> lub telefonicznie pod numerem: <a href="tel:739036056">739 036 056</a>.</p>
            <a class="btn-contact shadow" href="mailto:kontakt@mgabryel.com">
              <img src={Envelope} width="30px" alt="" />
              <span>Napisz e-mail</span>
            </a>
            <a class="btn-contact shadow ml-4" href="tel:739036056">
              <img src={Mobile} width="30px" alt="" />
              <span>Zadzwoń</span>
            </a>
        </div>
      </div>
    </div>

  </Layout>
)

export default IndexPage
